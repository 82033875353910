//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiToolbar from 'kolibri.coreVue.components.UiToolbar';
import { validateLinkObject } from 'kolibri.utils.validators';

export default {
  name: 'ImmersiveToolbar',
  components: {
    UiToolbar,
  },
  props: {
    appBarTitle: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'close',
      validator(val) {
        return ['close', 'back'].includes(val);
      },
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    route: {
      type: Object,
      required: false,
      validator: validateLinkObject,
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasRoute() {
      return Boolean(this.route);
    },
    linkStyle() {
      const hoverBg = this.isFullscreen
        ? this.$themeTokens.appBarDark
        : this.$themeTokens.appBarFullscreenDark;
      const defaultBg = this.isFullscreen
        ? this.$themeTokens.appBar
        : this.$themeTokens.appBarFullscreen;
      return {
        backgroundColor: defaultBg,
        ':hover': {
          backgroundColor: hoverBg,
        },
      };
    },
  },
};

