//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import last from 'lodash/last';

export default {
  name: 'CoreMenu',
  props: {
    // Whether to show if links are currently active
    showActive: {
      type: Boolean,
      default: true,
    },
    hasSecondaryText: {
      type: Boolean,
      default: false,
    },
    containFocus: {
      type: Boolean,
      default: false,
    },
    raised: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      showActive: this.showActive,
    };
  },
  data() {
    return {
      containTopFocus: false,
    };
  },
  computed: {
    classes() {
      return {
        'is-raised': this.raised,
        'has-secondary-text': this.hasSecondaryText,
      };
    },
  },
  watch: {
    isOpen(val) {
      if (val === false) {
        this.containTopFocus = false;
      }
    },
  },
  methods: {
    focusFirstOption() {
      this.$el.querySelector('.core-menu-option').focus();
    },
    focusLastOption() {
      const lastOption = last(this.$el.querySelectorAll('.core-menu-option'));
      if (lastOption) {
        lastOption.focus();
      }
    },
    handleFirstTrapFocus(e) {
      e.stopPropagation();
      if (!this.containTopFocus) {
        // On first focus, redirect to first option, then activate trap
        this.focusFirstOption();
        this.containTopFocus = true;
      } else {
        this.focusLastOption();
      }
    },
    handleLastTrapFocus(e) {
      e.stopPropagation();
      this.focusFirstOption();
    },
  },
};

