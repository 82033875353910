var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ backgroundColor: _vm.$themeTokens.surface })},[_c('h3',{staticClass:"header",style:(_vm.iconStyle)},[_vm._v("\n    "+_vm._s(_vm.$tr('answerHistoryLabel'))+"\n  ")]),_c('ul',{ref:"attemptList",staticClass:"history-list",attrs:{"role":"listbox"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"home",undefined,$event.key,undefined)){ return null; }return _vm.setSelectedAttemptLog(0)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"end",undefined,$event.key,undefined)){ return null; }return _vm.setSelectedAttemptLog(_vm.attemptLogs.length - 1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();_vm.setSelectedAttemptLog(_vm.previousQuestion(_vm.selectedQuestionNumber))},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();_vm.setSelectedAttemptLog(_vm.previousQuestion(_vm.selectedQuestionNumber))},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();_vm.setSelectedAttemptLog(_vm.nextQuestion(_vm.selectedQuestionNumber))},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();_vm.setSelectedAttemptLog(_vm.nextQuestion(_vm.selectedQuestionNumber))}]}},[_vm._l((_vm.attemptLogs),function(attemptLog,index){return [_c('li',{key:index,staticClass:"attempt-item",style:({
          backgroundColor: _vm.isSelected(index) ? _vm.$themeTokens.textDisabled : '',
        })},[_c('a',{ref:"attemptListOption",refInFor:true,style:(_vm.iconStyle),attrs:{"role":"option","aria-selected":_vm.isSelected(index).toString(),"tabindex":_vm.isSelected(index) ? 0 : -1},on:{"click":function($event){$event.preventDefault();return _vm.setSelectedAttemptLog(index)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setSelectedAttemptLog(index)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.setSelectedAttemptLog(index)}]}},[(attemptLog.noattempt)?_c('KIcon',{staticClass:"item svg-item",style:({ fill: _vm.$themeTokens.annotation }),attrs:{"icon":"cancel"}}):(attemptLog.correct)?_c('KIcon',{staticClass:"item svg-item",style:({ fill: _vm.$themeTokens.correct }),attrs:{"icon":"correct"}}):(attemptLog.error)?_c('KIcon',{staticClass:"svg-item",style:({ fill: _vm.$themeTokens.annotation }),attrs:{"icon":"helpNeeded"}}):(!attemptLog.correct)?_c('KIcon',{staticClass:"item svg-item",style:({ fill: _vm.$themeTokens.incorrect }),attrs:{"icon":"incorrect"}}):(attemptLog.hinted)?_c('KIcon',{staticClass:"item svg-item",style:({ fill: _vm.$themeTokens.annotation }),attrs:{"icon":"hint"}}):_vm._e(),_c('p',{staticClass:"item"},[_vm._v("\n            "+_vm._s(_vm.windowIsLarge ?
                _vm.coreString(
                  'questionNumberLabel',
                  { questionNumber: attemptLog.questionNumber }
                )
                :
                // Add non-breaking space to preserve vertical centering
                " ")+"\n          ")]),(_vm.windowIsLarge)?_c('CoachContentLabel',{staticClass:"coach-content-label",attrs:{"value":attemptLog.num_coach_contents || 0,"isTopic":false}}):_vm._e()],1)])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }