//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PrivacyInfoModal from 'kolibri.coreVue.components.PrivacyInfoModal';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'PrivacyLinkAndModal',
  components: {
    PrivacyInfoModal,
  },
  mixins: [commonCoreStrings],
  props: {
    text: {
      type: String,
      required: false,
    },
    modalProps: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      privacyModalVisible: false,
    };
  },
};

