//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';

export default {
  name: 'BottomAppBar',
  mixins: [responsiveWindowMixin],
  props: {
    maxWidth: {
      type: Number,
      default: 960,
    },
  },
  computed: {
    innerStyle() {
      if (this.maxWidth) {
        return { maxWidth: `${this.maxWidth}px` };
      }
      return null;
    },
  },
};

