//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiAlert from 'kolibri-design-system/lib/keen/UiAlert';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import commonSyncElements from 'kolibri.coreVue.mixins.commonSyncElements';

// This is a SelectSourceModal template with the common heading and loading
// message widget. Place the form (usually a radio button group)
// inside the default slot.
export default {
  name: 'SelectSourceModal',
  components: { UiAlert },
  mixins: [commonCoreStrings, commonSyncElements],
  props: {
    showLoadingMessage: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      required: true,
    },
  },
  $trs: {
    loadingMessage: 'Loading connections…',
  },
};

