//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'CoreBanner',
  mixins: [commonCoreStrings],
  data() {
    return {
      bannerClosed: false,
    };
  },
  created() {
    document.addEventListener('focusin', this.focusChange);
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChange);
  },
  methods: {
    toggleBanner() {
      this.bannerClosed = !this.bannerClosed;
      if (this.previouslyFocusedElement) {
        this.previouslyFocusedElement.focus();
      }
    },
    focusChange(e) {
      // We need the element prior to the close button and more info
      if (
        (this.$refs.close_button && e.target != this.$refs.close_button.$el) ||
        (this.$refs.open_button && e.target != this.$refs.open_button.$el)
      ) {
        this.previouslyFocusedElement = e.target;
      }
    },
  },
  $trs: {
    openButton: 'More Info',
  },
};

