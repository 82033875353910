//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import commonSyncElements from 'kolibri.coreVue.mixins.commonSyncElements';
import UsernameTextbox from 'kolibri.coreVue.components.UsernameTextbox';
import PasswordTextbox from 'kolibri.coreVue.components.PasswordTextbox';

export default {
  name: 'FacilityAdminCredentialsForm',
  components: {
    UsernameTextbox,
    PasswordTextbox,
  },
  mixins: [commonCoreStrings, commonSyncElements],
  props: {
    device: {
      type: Object,
      required: true,
      validator(val) {
        return val.name && val.id && val.baseurl;
      },
    },
    facility: {
      type: Object,
      required: true,
    },
    singleFacility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: '',
      password: '',
      passwordValid: false,
      usernameValid: false,
      shouldValidate: false,
      error: false,
    };
  },
  computed: {
    prompt() {
      const deviceName = this.device.name;
      if (this.singleFacility) {
        return this.$tr('adminCredentialsPromptOneFacility', {
          device: deviceName,
        });
      } else {
        return this.$tr('adminCredentialsPromptMultipleFacilities', {
          facility: this.facility.name,
          device: deviceName,
        });
      }
    },
    formIsValid() {
      return this.passwordValid && this.usernameValid;
    },
  },
  methods: {
    // @public. Returns Promise<Boolean>
    startImport() {
      this.shouldValidate = true;
      if (this.formIsValid) {
        return this.startPeerImportTask({
          device_name: this.device.name,
          device_id: this.device.id,
          facility_name: this.facility.name,
          facility: this.facility.id,
          baseurl: this.device.baseurl,
          username: this.username,
          password: this.password,
        })
          .then(task => {
            return task.id;
          })
          .catch(() => {
            this.error = true;
            this.refocusForm();
            return false;
          });
      } else {
        this.refocusForm();
        return Promise.resolve(false);
      }
    },
    refocusForm() {
      this.$nextTick().then(() => {
        if (!this.usernameValid || this.error) {
          this.$refs.username.focus();
        } else if (!this.passwordValid) {
          this.$refs.password.focus();
        }
      });
    },
  },
  $trs: {
    // Use this version in Device > Facilities
    adminCredentialsPromptMultipleFacilities: {
      message:
        "Enter the username and password for a facility admin of '{facility}' or a super admin of '{device}'",
      context:
        '\n        Menu description text: users must provide the facility admin credentials\n        for a selected source facility, or super admin credentials for the source\n        device, before they are able to import\n      ',
    },
    adminCredentialsPromptOneFacility: {
      message:
        "Enter the username and password for a facility admin or a super admin of '{device}'",
      context: 'Alternative phrasing for the facility admin credentials prompt',
    },
    duplicateFacilityNamesExplanation: {
      message: "This facility is different from '{facilities}'. These facilities will not sync.",
      context: 'Explanation displayed when two facilities with the same name are on the device',
    },
  },
};

