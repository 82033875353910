//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import responsiveElementMixin from 'kolibri.coreVue.mixins.responsiveElementMixin';

export default {
  name: 'MultiPaneLayout',
  mixins: [responsiveWindowMixin, responsiveElementMixin],
  computed: {
    maxHeight() {
      const APP_BAR_HEIGHT = this.windowIsSmall ? 56 : 64;
      const PADDING = this.windowIsSmall ? 16 : 32;
      const MARGIN = 16;
      let maxHeight = this.windowHeight - APP_BAR_HEIGHT - PADDING * 2 - MARGIN;
      if (this.$refs.header) {
        maxHeight = maxHeight - this.$refs.header.clientHeight;
      }
      if (this.$refs.footer) {
        maxHeight = maxHeight - this.$refs.footer.clientHeight;
      }
      return maxHeight;
    },
    styles() {
      return {
        header: {
          borderBottomColor: this.$themeTokens.textDisabled,
        },
        aside: {
          maxHeight: `${this.maxHeight}px`,
        },
        main: {
          maxHeight: this.$slots.aside ? `${this.maxHeight}px` : '',
        },
        footer: {
          borderTopColor: this.$themeTokens.textDisabled,
        },
      };
    },
  },
  methods: {
    /**
     * @public
     */
    scrollMainToTop() {
      this.$refs.main.scrollTop = 0;
    },
  },
};

