//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import CoachContentLabel from 'kolibri.coreVue.components.CoachContentLabel';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';

export default {
  name: 'AttemptLogList',
  components: {
    CoachContentLabel,
  },
  mixins: [commonCoreStrings, responsiveWindowMixin],
  props: {
    attemptLogs: {
      type: Array,
      required: true,
    },
    selectedQuestionNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    iconStyle() {
      if (this.windowIsLarge) {
        return {};
      } else {
        return {
          textAlign: 'center',
          padding: 0,
        };
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToSelectedAttemptLog(this.selectedQuestionNumber);
    });
  },
  methods: {
    setSelectedAttemptLog(questionNumber) {
      const listOption = this.$refs.attemptListOption[questionNumber];
      listOption.focus();

      this.$emit('select', questionNumber);
      this.scrollToSelectedAttemptLog(questionNumber);
    },
    isSelected(questionNumber) {
      return Number(this.selectedQuestionNumber) === questionNumber;
    },
    scrollToSelectedAttemptLog(questionNumber) {
      const selectedElement = this.$refs.attemptList.children[questionNumber];
      if (selectedElement) {
        const parent = this.$el.parentElement;
        parent.scrollTop =
          selectedElement.offsetHeight * (questionNumber + 1) - parent.offsetHeight / 2;
      }
    },
    previousQuestion(questionNumber) {
      return questionNumber - 1 >= 0 ? questionNumber - 1 : this.attemptLogs.length - 1;
    },
    nextQuestion(questionNumber) {
      return questionNumber + 1 < this.attemptLogs.length ? questionNumber + 1 : 0;
    },
  },
  $trs: {
    answerHistoryLabel: 'Answer history',
  },
};

