//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import throttle from 'lodash/throttle';
import UiIcon from 'kolibri-design-system/lib/keen/UiIcon';
/**
 * Used to filter items via text input
 */
export default {
  name: 'FilterTextbox',
  components: {
    UiIcon,
  },
  props: {
    /**
     * v-model
     */
    value: {
      type: String,
    },
    /**
     * Placeholder
     */
    placeholder: {
      type: String,
      required: true,
    },
    /**
     * Whether to autofocus
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
    // If provided, will throttle and use the prop as the delay value in msecs
    throttleInput: {
      type: Number,
      required: false,
      default: 15,
    },
  },
  computed: {
    throttledEmitInput() {
      return throttle(val => {
        // This will also be triggered on keyUp for Android
        // where the keyboard may not trigger a `model.set` call
        // and thereby not triggering this
        if (val !== this.value) {
          this.$emit('input', val);
        }
      }, this.throttleInput);
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        /**
         * Emits input event with new value
         */
        if (this.throttleInput) {
          this.throttledEmitInput(val);
        } else {
          this.$emit('input', val);
        }
      },
    },
    kFilterPlaceHolderStyle() {
      return {
        '::placeholder': {
          color: this.$themeTokens.annotation,
        },
      };
    },
  },
  methods: {
    handleClickClear() {
      this.model = '';
      this.$refs.searchinput.focus();
    },
  },
  $trs: {
    filter: 'filter',
    clear: 'clear',
  },
};

