//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions } from 'vuex';
import ClipboardJS from 'clipboard';

export default {
  name: 'TechnicalTextBlock',
  props: {
    text: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: Number,
      required: false,
    },
    minHeight: {
      type: Number,
      default: 72,
    },
  },
  computed: {
    clipboardCapable() {
      return ClipboardJS.isSupported();
    },
    dynamicHeightStyle() {
      return {
        height: `${16 + this.text.split('\n').length * 18}px`,
        maxHeight: `${this.maxHeight}px`,
        minHeight: `${this.minHeight}px`,
      };
    },
  },
  mounted() {
    if (this.clipboardCapable) {
      this.clipboard = new ClipboardJS(this.$refs.copyButton.$el, {
        text: () => this.text,
        // needed because modal changes browser focus
        container: this.$refs.textBox,
      });

      this.clipboard.on('success', () => {
        this.createSnackbar(this.$tr('copiedToClipboardConfirmation'));
      });
    }
  },
  destroyed() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },
  methods: {
    ...mapActions(['createSnackbar']),
  },
  $trs: {
    copyToClipboardButtonPrompt: 'Copy to clipboard',
    copiedToClipboardConfirmation: 'Copied to clipboard',
  },
};

