//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import { UserKinds, NavComponentSections } from 'kolibri.coreVue.vuex.constants';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import responsiveElementMixin from 'kolibri.coreVue.mixins.responsiveElementMixin';
import CoreMenu from 'kolibri.coreVue.components.CoreMenu';
import CoreLogo from 'kolibri.coreVue.components.CoreLogo';
import navComponents from 'kolibri.utils.navComponents';
import PrivacyInfoModal from 'kolibri.coreVue.components.PrivacyInfoModal';
import branding from 'kolibri.utils.branding';
import navComponentsMixin from '../mixins/nav-components';
import logout from './LogoutSideNavEntry';
import SideNavDivider from './SideNavDivider';

// Explicit ordered list of roles for nav item sorting
const navComponentRoleOrder = [
  UserKinds.ANONYMOUS,
  UserKinds.LEARNER,
  UserKinds.COACH,
  UserKinds.ADMIN,
  UserKinds.CAN_MANAGE_CONTENT,
  UserKinds.SUPERUSER,
];

export default {
  name: 'SideNav',
  components: {
    CoreMenu,
    CoreLogo,
    SideNavDivider,
    PrivacyInfoModal,
  },
  mixins: [commonCoreStrings, responsiveWindowMixin, responsiveElementMixin, navComponentsMixin],
  props: {
    navShown: {
      type: Boolean,
      required: true,
    },
    headerHeight: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      previouslyFocusedElement: null,
      // __copyrightYear is injected by Webpack DefinePlugin
      copyrightYear: __copyrightYear,
      privacyModalVisible: false,
    };
  },
  computed: {
    footerMsg() {
      return this.$tr('poweredBy', { version: __version });
    },
    menuOptions() {
      const topComponents = navComponents
        .filter(component => component.section !== NavComponentSections.ACCOUNT)
        .sort(this.compareMenuComponents);
      const accountComponents = navComponents
        .filter(component => component.section === NavComponentSections.ACCOUNT)
        .sort(this.compareMenuComponents);
      return [...topComponents, SideNavDivider, ...accountComponents, logout].filter(
        this.filterByRole
      );
    },
    sideNavTitleText() {
      if (this.$kolibriBranding.sideNav.title) {
        return this.$kolibriBranding.sideNav.title;
      }
      return this.coreString('kolibriLabel');
    },
  },
  watch: {
    navShown(isShown) {
      this.$nextTick(() => {
        if (isShown) {
          window.addEventListener('focus', this.containFocus, true);
          this.previouslyFocusedElement = document.activeElement;
          this.$refs.sideNav.focus();
        } else {
          window.removeEventListener('focus', this.containFocus, true);
          this.previouslyFocusedElement.focus();
        }
      });
    },
  },
  created() {
    this.$kolibriBranding = branding;
  },
  methods: {
    toggleNav() {
      this.$emit('toggleSideNav');
    },
    handleClickPrivacyLink() {
      this.privacyModalVisible = true;
    },
    compareMenuComponents(navComponentA, navComponentB) {
      // Compare menu items to allow sorting by the following priority:
      // Sort by role
      // Nav items with no roles will be placed first
      // as index will be -1
      if (navComponentA.role !== navComponentB.role) {
        return (
          navComponentRoleOrder.indexOf(navComponentA.role) -
          navComponentRoleOrder.indexOf(navComponentB.role)
        );
      }
      // Next sort by priority
      if (navComponentA.priority !== navComponentB.priority) {
        return navComponentA.priority - navComponentB.priority;
      }
      // Still no difference?
      // There is no difference!
      return 0;
    },
    containFocus(event) {
      if (event.target === window) {
        return event;
      }
      if (!this.$refs.sideNav.contains(event.target)) {
        this.$refs.coreMenu.$el.focus();
      }
      return event;
    },
  },
  $trs: {
    navigationLabel: 'Main user navigation',
    closeNav: 'Close navigation',
    poweredBy: 'Kolibri {version}',
  },
};

