var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],ref:"sideNav",staticClass:"side-nav-wrapper",attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleNav($event)}}},[_c('transition',{attrs:{"name":"side-nav"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],staticClass:"side-nav",style:({
        width: (_vm.width + "px"),
        color: _vm.$themeTokens.text,
        backgroundColor: _vm.$themeTokens.surface,
      })},[_c('div',{staticClass:"side-nav-header",style:({
          height: _vm.headerHeight + 'px',
          width: (_vm.width + "px"), paddingTop: _vm.windowIsSmall ? '4px' : '8px',
          backgroundColor: _vm.$themeTokens.appBar,
        })},[_c('KIconButton',{staticClass:"side-nav-header-icon",attrs:{"icon":"close","color":_vm.$themeTokens.textInverted,"ariaLabel":_vm.$tr('closeNav'),"size":"large"},on:{"click":_vm.toggleNav}}),_c('span',{staticClass:"side-nav-header-name",style:({ color: _vm.$themeTokens.textInverted })},[_vm._v(_vm._s(_vm.sideNavTitleText))])],1),_c('div',{staticClass:"side-nav-scrollable-area",style:({ top: (_vm.headerHeight + "px"), width: (_vm.width + "px") })},[(_vm.$kolibriBranding.sideNav.topLogo)?_c('img',{staticClass:"logo",style:(_vm.$kolibriBranding.sideNav.topLogo.style),attrs:{"src":_vm.$kolibriBranding.sideNav.topLogo.src,"alt":_vm.$kolibriBranding.sideNav.topLogo.alt}}):_vm._e(),_c('CoreMenu',{ref:"coreMenu",style:({ backgroundColor: _vm.$themeTokens.surface }),attrs:{"role":"navigation","aria-label":_vm.$tr('navigationLabel')},scopedSlots:_vm._u([{key:"options",fn:function(){return [_vm._l((_vm.menuOptions),function(component){return _c(component,{key:component.name,tag:"component"})}),_c('SideNavDivider')]},proxy:true}])}),_c('div',{staticClass:"side-nav-scrollable-area-footer",style:({ color: _vm.$themeTokens.annotation })},[(_vm.$kolibriBranding.sideNav.brandedFooter)?[(_vm.$kolibriBranding.sideNav.brandedFooter.logo)?_c('img',{staticClass:"side-nav-scrollable-area-footer-logo",style:(_vm.$kolibriBranding.sideNav.brandedFooter.logo.style),attrs:{"src":_vm.$kolibriBranding.sideNav.brandedFooter.logo.src,"alt":_vm.$kolibriBranding.sideNav.brandedFooter.logo.alt}}):_vm._e(),(_vm.$kolibriBranding.sideNav.brandedFooter.paragraphArray
                && _vm.$kolibriBranding.sideNav.brandedFooter.paragraphArray.length)?_c('div',{staticClass:"side-nav-scrollable-area-footer-info"},_vm._l((_vm.$kolibriBranding.sideNav.brandedFooter.paragraphArray),function(line,index){return _c('p',{key:index},[_vm._v("\n                "+_vm._s(line)+"\n              ")])}),0):_vm._e()]:_vm._e(),(_vm.$kolibriBranding.sideNav.showKolibriFooterLogo)?_c('CoreLogo',{staticClass:"side-nav-scrollable-area-footer-logo"}):_vm._e(),_c('div',{staticClass:"side-nav-scrollable-area-footer-info"},[_c('p',[_vm._v(_vm._s(_vm.footerMsg))]),_c('p',[_vm._v("© "+_vm._s(_vm.copyrightYear)+" Learning Equality")]),_c('p',[_c('KButton',{ref:"privacyLink",staticClass:"privacy-link",attrs:{"text":_vm.coreString('usageAndPrivacyLabel'),"appearance":"basic-link"},on:{"click":_vm.handleClickPrivacyLink}})],1)])],2)],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navShown),expression:"navShown"}],staticClass:"side-nav-backdrop",on:{"click":_vm.toggleNav}}),(_vm.privacyModalVisible)?_c('PrivacyInfoModal',{on:{"cancel":function($event){_vm.privacyModalVisible = false},"submit":function($event){_vm.privacyModalVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }