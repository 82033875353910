//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveElementMixin from 'kolibri.coreVue.mixins.responsiveElementMixin';
import throttle from 'lodash/throttle';

/**
 * Used for navigation between sub-pages of a top-level Kolibri section
 */
export default {
  name: 'Navbar',
  mixins: [responsiveElementMixin],
  data() {
    return {
      enoughSpace: true,
    };
  },
  computed: {
    maxWidth() {
      return this.enoughSpace ? this.elementWidth : this.elementWidth - 38 * 2;
    },
    wrapperClass() {
      if (!this.enoughSpace) {
        return ['wrapper-narrow'];
      }

      return [];
    },
  },
  mounted() {
    this.checkSpace();
    this.$watch('elementWidth', this.throttleCheckSpace);
  },
  methods: {
    checkSpace() {
      const availableWidth = this.elementWidth;
      const items = this.$children;
      let widthOfItems = 0;
      items.forEach(item => {
        const itemWidth = Math.ceil(item.$el.getBoundingClientRect().width);
        widthOfItems += itemWidth;
      });
      // Subtract 16px to account for padding-left
      this.enoughSpace = widthOfItems <= availableWidth - 16;
    },
    throttleCheckSpace: throttle(function() {
      this.checkSpace();
    }, 100),
    handleClickPrevious() {
      this.isRtl ? this.scrollRight() : this.scrollLeft();
    },
    handleClickNext() {
      this.isRtl ? this.scrollLeft() : this.scrollRight();
    },
    scrollLeft() {
      this.$refs.navbarUl.scrollLeft -= this.maxWidth;
    },
    scrollRight() {
      this.$refs.navbarUl.scrollLeft += this.maxWidth;
    },
  },
};

