//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions } from 'vuex';
import UiSnackbar from 'kolibri-design-system/lib/keen/UiSnackbar.vue';

/* Snackbars are used to display notification. */
export default {
  name: 'CoreSnackbar',
  components: {
    UiSnackbar,
  },
  props: {
    /* Text of notification to be displayed */
    text: {
      type: String,
      required: true,
    },
    /* To provide an action button, provide text */
    actionText: {
      type: String,
      required: false,
    },
    /* Automatically dismiss the snackbar */
    autoDismiss: {
      type: Boolean,
      default: false,
    },
    /* Duration that the snackbar is visible before it is automatically dismissed */
    duration: {
      type: Number,
      default: 4000,
    },
    /* Show a backdrop to prevent interaction with the page */
    backdrop: {
      type: Boolean,
      default: false,
    },
    /* Integer that over-rides the default 'bottom: 0' CSS */
    bottomPosition: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      timeout: null,
      isVisible: false,
      previouslyFocusedElement: null,
    };
  },
  computed: {
    styles() {
      if (this.bottomPosition) {
        return {
          bottom: `${this.bottomPosition}px`,
        };
      }
      return {};
    },
  },
  mounted() {
    this.isVisible = true;
    if (this.autoDismiss) {
      this.timeout = window.setTimeout(this.hideSnackbar, this.duration);
    }
    if (this.backdrop) {
      this.previouslyFocusedElement = document.activeElement;
      this.previouslyFocusedElement.blur();
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    if (this.backdrop) {
      this.previouslyFocusedElement.focus();
    }
  },
  methods: {
    ...mapActions(['clearSnackbar']),
    hideSnackbar() {
      this.isVisible = false;
      this.$emit('hide');
    },
    handleActionClick() {
      this.isVisible = false;
      this.$emit('actionClicked');
    },
    focusSnackbarElement() {
      this.$refs.snackbar.$el.focus();
    },
    handleOnEnter() {
      if (this.backdrop) {
        this.focusSnackbarElement();
      }
    },
    trapFocus(e) {
      e.stopPropagation();
      this.focusSnackbarElement();
    },
  },
};

