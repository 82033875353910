//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { PermissionTypes, UserKinds } from 'kolibri.coreVue.vuex.constants';
import UserTypeDisplay from 'kolibri.coreVue.components.UserTypeDisplay';

export default {
  name: 'PermissionsIcon',
  components: {
    UserTypeDisplay,
  },
  props: {
    permissionType: {
      type: String,
      required: true,
      validator(value) {
        return [PermissionTypes.SUPERUSER, PermissionTypes.LIMITED_PERMISSIONS].includes(value);
      },
    },
    lightIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    UserKinds() {
      return UserKinds;
    },
    hasSuperAdminPermission() {
      return this.permissionType === PermissionTypes.SUPERUSER;
    },
    hasLimitedPermissions() {
      return this.permissionType === PermissionTypes.LIMITED_PERMISSIONS;
    },
    iconStyle() {
      if (this.hasSuperAdminPermission) {
        return {
          fill: this.lightIcon ? this.$themePalette.amber.v_100 : this.$themeTokens.superAdmin,
        };
      } else {
        return {
          fill: this.lightIcon ? this.$themeTokens.disabled : this.$themeTokens.text,
        };
      }
    },
  },
  $trs: {
    limitedPermissionsTooltip: 'Limited permissions',
  },
};

