//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UiIcon from 'kolibri-design-system/lib/keen/UiIcon';

export default {
  name: 'CoreInfoIcon',
  components: {
    UiIcon,
  },
  props: {
    iconAriaLabel: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
    tooltipPlacement: {
      type: String,
      required: false,
    },
  },
};

