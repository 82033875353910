//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { validateContentNodeKind } from 'kolibri.utils.validators';
import { ContentNodeKinds, USER } from 'kolibri.coreVue.vuex.constants';
import UiIcon from 'kolibri-design-system/lib/keen/UiIcon';

export default {
  name: 'ContentIcon',
  components: {
    UiIcon,
  },
  props: {
    kind: {
      type: String,
      required: true,
      validator(value) {
        return validateContentNodeKind(value, [USER]);
      },
    },
    colorStyle: {
      type: String,
      default: 'action',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'black',
      required: false,
    },
  },
  computed: {
    ContentNodeKinds() {
      return ContentNodeKinds;
    },
    USER() {
      return USER;
    },
    colorClass() {
      return `color-${this.colorStyle}`;
    },
    tooltipText() {
      const kindToLabeLMap = {
        [ContentNodeKinds.TOPIC]: 'topic',
        [ContentNodeKinds.CHANNEL]: 'channel',
        [ContentNodeKinds.EXERCISE]: 'exercise',
        [ContentNodeKinds.VIDEO]: 'video',
        [ContentNodeKinds.AUDIO]: 'audio',
        [ContentNodeKinds.DOCUMENT]: 'document',
        [ContentNodeKinds.HTML5]: 'html5',
        [ContentNodeKinds.EXAM]: 'exam',
        [ContentNodeKinds.LESSON]: 'lesson',
        [ContentNodeKinds.SLIDESHOW]: 'slideshow',
        [USER]: 'user',
      };
      const label = kindToLabeLMap[this.kind];
      return label ? this.$tr(label) : '';
    },
  },
  methods: {
    is(kind) {
      return this.kind === kind;
    },
  },
  $trs: {
    topic: 'Topic',
    channel: 'Channel',
    exercise: 'Exercise',
    video: 'Video',
    audio: 'Audio',
    document: 'Document',
    html5: 'App',
    exam: 'Quiz',
    lesson: 'Lesson',
    user: 'User',
    slideshow: 'Slideshow',
  },
};

