//
//
//
//
//
//
//


import CoreMenuOption from 'kolibri.coreVue.components.CoreMenuOption';

export default {
  name: 'SideNavDivider',
  components: {
    CoreMenuOption,
  },
};

