//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import ElapsedTime from 'kolibri.coreVue.components.ElapsedTime';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';

export default {
  name: 'PageStatus',
  components: {
    ProgressIcon,
    ElapsedTime,
  },
  mixins: [commonCoreStrings],
  props: {
    userName: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    completed: {
      type: Boolean,
      default: false,
    },
    completionTimestamp: { type: Date },
    contentName: {
      type: String,
      required: true,
    },
  },
  computed: {
    questionsCorrect() {
      return this.questions.reduce((a, q) => a + (q.correct === 1 ? 1 : 0), 0);
    },
    score() {
      return this.questions.reduce((a, q) => a + q.correct, 0) / this.questions.length || 0;
    },
    progress() {
      // Either return in completed or in progress
      return this.completed ? 1 : 0.1;
    },
    progressIconLabel() {
      if (this.completed) {
        return this.coreString('completedLabel');
      } else if (this.completed !== null) {
        return this.$tr('inProgress');
      } else {
        return this.$tr('notStartedLabel');
      }
    },
  },
  $trs: {
    overallScore: 'Overall score',
    questionsCorrectLabel: 'Questions correct',
    questionsCorrectValue: '{correct, number} out of {total, number}',
    inProgress: 'In progress',
    notStartedLabel: 'Not started',
  },
};

