//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'DragSortWidget',
  props: {
    moveUpText: {
      type: String,
      required: true,
    },
    moveDownText: {
      type: String,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  mounted() {
    window.addEventListener('focus', this.updateFocus, true);
  },
  destroyed() {
    window.removeEventListener('focus', this.updateFocus, true);
  },
  methods: {
    updateFocus() {
      this.hasFocus = [this.$refs.dnBtn.$el, this.$refs.upBtn.$el].includes(
        document.activeElement
      );
    },
    clickDown() {
      this.$emit('moveDown');
      this.$nextTick(() => {
        if (this.isLast) {
          this.$refs.upBtn.$el.focus();
        } else {
          this.$refs.dnBtn.$el.focus();
        }
      });
    },
    clickUp() {
      this.$emit('moveUp');
      this.$nextTick(() => {
        if (this.isFirst) {
          this.$refs.dnBtn.$el.focus();
        } else {
          this.$refs.upBtn.$el.focus();
        }
      });
    },
  },
};

