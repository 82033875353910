//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { validateLinkObject } from 'kolibri.utils.validators';
import UiIcon from 'kolibri-design-system/lib/keen/UiIcon';

/**
 Links for use inside the Navbar
 */
export default {
  name: 'NavbarLink',
  components: { UiIcon },
  props: {
    /**
     * The text
     */
    title: {
      type: String,
      required: false,
    },
    /**
     * A router link object
     */
    link: {
      type: Object,
      required: true,
      validator: validateLinkObject,
    },
  },
  computed: {
    tabStyles() {
      return {
        color: this.$themePalette.grey.v_100,
        ':hover': {
          'background-color': this.$themeTokens.appBarDark,
        },
        ':focus': {
          ...this.$coreOutline,
          outlineOffset: '-6px',
        },
      };
    },
  },
};

