//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { currentLanguage } from 'kolibri.utils.i18n';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import languageSwitcherMixin from './mixin';

export default {
  name: 'LanguageSwitcherModal',
  mixins: [commonCoreStrings, languageSwitcherMixin, responsiveWindowMixin],
  data() {
    return {
      selectedLanguage: currentLanguage,
    };
  },
  computed: {
    splitLanguageOptions() {
      let secondCol = this.languageOptions;
      let firstCol = secondCol.splice(0, Math.ceil(secondCol.length / 2));

      return [firstCol, secondCol];
    },
  },
  methods: {
    setLang() {
      if (currentLanguage === this.selectedLanguage) {
        this.cancel();
        return;
      }

      this.switchLanguage(this.selectedLanguage);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  $trs: {
    changeLanguageModalHeader: 'Change language',
  },
};

