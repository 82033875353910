

import get from 'lodash/get';

export default {
  name: 'CoreTable',
  props: {
    selectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    emptyMessage: {
      type: String,
      required: false,
    },
  },
  computed: {
    tHeadStyle() {
      return {
        borderBottom: `solid 1px ${this.$themeTokens.fineLine}`,
        fontSize: '12px',
        color: this.$themeTokens.annotation,
      };
    },
    tbodyTrStyle() {
      const selectable = {
        cursor: 'pointer',
        ':hover': {
          backgroundColor: this.$themePalette.grey.v_100,
        },
      };
      return Object.assign(
        {
          ':not(:last-child)': {
            borderBottom: `solid 1px ${this.$themeTokens.fineLine}`,
          },
        },
        this.selectable ? selectable : {}
      );
    },
  },
  render(createElement) {
    let tableHasRows = true;

    // create <thead> element with #headers slot
    const theadEl = createElement('thead', { style: this.tHeadStyle }, [
      createElement('tr', {}, this.$slots.headers),
    ]);

    const tbodyCopy = [...this.$slots.tbody];
    tbodyCopy.forEach(tbody => {
      // Need to check componentOptions if wrapped in <transition-group>, or just children
      // if in regular <tbody>
      const tgroupChildren = get(tbody, 'componentOptions.children');
      if (tgroupChildren) {
        tableHasRows = tgroupChildren.length > 0;
      }

      if (tbody.children) {
        tableHasRows = tbody.children.length > 0;
        tbody.children.forEach(child => {
          if (!child.data) {
            child.data = {};
          }
          if (!child.data.class) {
            child.data.class = [];
          } else if (child.data.class && !Array.isArray(child.data.class)) {
            child.data.class = [child.data.class];
          }
          child.data.class.push(this.$computedClass(this.tbodyTrStyle));
        });
      }
    });

    // Insert an empty message as a <p> at the end if it is provided and the
    // table has no rows.
    const showEmptyMessage = this.emptyMessage && !tableHasRows;

    return createElement('div', { class: 'core-table-container' }, [
      createElement('table', { class: 'core-table' }, [
        ...(this.$slots.default || []),
        theadEl,
        tbodyCopy,
      ]),
      showEmptyMessage && createElement('p', this.emptyMessage),
    ]);
  },
};

