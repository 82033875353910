//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import UiIcon from 'kolibri-design-system/lib/keen/UiIcon';

export default {
  name: 'ProgressIcon',
  components: {
    UiIcon,
  },
  mixins: [commonCoreStrings],
  props: {
    progress: {
      type: Number,
      required: false,
      validator(value) {
        return value >= 0 && value <= 1;
      },
    },
  },
  computed: {
    isInProgress() {
      // this logic is updated to be consistent with the logic in CardThumbnail
      return this.progress !== null && this.progress > 0 && this.progress < 1;
    },
    isCompleted() {
      return this.progress >= 1;
    },
  },
  methods: {
    iconStyle(bgColor) {
      return {
        backgroundColor: bgColor,
        color: this.$themeTokens.textInverted,
      };
    },
  },
  $trs: {
    inProgress: 'In progress',
  },
};

