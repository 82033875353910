//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { availableLanguages, currentLanguage } from 'kolibri.utils.i18n';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import { compareLanguages } from 'kolibri.utils.sortLanguages';
import languageSwitcherMixin from './mixin';
import LanguageSwitcherModal from './LanguageSwitcherModal';

const prioritizedLanguages = ['en', 'ar', 'es-419', 'hi-in', 'fr-fr', 'sw-tz'];

export default {
  name: 'LanguageSwitcherList',
  components: {
    LanguageSwitcherModal,
  },
  mixins: [responsiveWindowMixin, languageSwitcherMixin],
  data() {
    return {
      showLanguageModal: false,
    };
  },
  computed: {
    selectableLanguages() {
      return Object.values(availableLanguages).filter(lang => lang.id !== currentLanguage);
    },
    selectedLanguage() {
      return availableLanguages[currentLanguage];
    },
    numVisibleLanguages() {
      // At windowBreakpoint = 0, only the "More languages" button will show
      return this.windowBreakpoint;
    },
    numSelectableLanguages() {
      return this.selectableLanguages.length;
    },
    buttonLanguages() {
      if (this.selectableLanguages.length <= this.numVisibleLanguages + 1) {
        return this.selectableLanguages.slice().sort(compareLanguages);
      }
      return this.selectableLanguages
        .slice()
        .sort((a, b) => {
          const aPriority = prioritizedLanguages.includes(a.id);
          const bPriority = prioritizedLanguages.includes(b.id);
          if (aPriority && bPriority) {
            return compareLanguages(a, b);
          } else if (aPriority && !bPriority) {
            return -1;
          } else if (!aPriority && bPriority) {
            return 1;
          }
          return compareLanguages(a, b);
        })
        .slice(0, this.numVisibleLanguages);
    },
  },
  $trs: {
    showMoreLanguagesSelector: 'More languages',
  },
};

