//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import clamp from 'lodash/clamp';
import FilterTextbox from 'kolibri.coreVue.components.FilterTextbox';
import filterUsersByNames from 'kolibri.utils.filterUsersByNames';

export default {
  name: 'PaginatedListContainer',
  components: {
    FilterTextbox,
  },
  props: {
    // The entire list of items
    items: {
      type: Array,
      required: true,
    },
    filterPlaceholder: {
      type: String,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data() {
    return {
      filterInput: '',
      currentPage: 1,
    };
  },
  computed: {
    filteredItems() {
      return filterUsersByNames(this.items, this.filterInput);
    },
    numFilteredItems() {
      return this.filteredItems.length;
    },
    totalPages() {
      return Math.ceil(this.numFilteredItems / this.itemsPerPage);
    },
    startRange() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    visibleStartRange() {
      return Math.min(this.startRange + 1, this.numFilteredItems);
    },
    endRange() {
      return this.currentPage * this.itemsPerPage;
    },
    visibleEndRange() {
      return Math.min(this.endRange, this.numFilteredItems);
    },
    visibleFilteredItems() {
      return this.filteredItems.slice(this.startRange, this.endRange);
    },
    previousButtonDisabled() {
      return this.currentPage === 1 || this.numFilteredItems === 0;
    },
    nextButtonDisabled() {
      return (
        this.totalPages === 1 ||
        this.currentPage === this.totalPages ||
        this.numFilteredItems === 0
      );
    },
  },
  watch: {
    visibleFilteredItems: {
      handler(newVal) {
        this.$emit('pageChanged', {
          page: this.currentPage,
          items: newVal,
        });
      },
      immediate: true,
    },
  },
  methods: {
    changePage(change) {
      // Clamp the newPage number between the bounds if browser doesn't correctly
      // disable buttons (see #6454 issue with old versions of MS Edge)
      this.currentPage = clamp(this.currentPage + change, 1, this.totalPages);
    },
  },
  $trs: {
    previousResults: 'Previous results',
    nextResults: 'Next results',
    pagination:
      '{ visibleStartRange, number } - { visibleEndRange, number } of { numFilteredItems, number }',
  },
};

