//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import SelectSourceModal from './SelectSourceModal';

const SyncSources = Object.freeze({
  PORTAL: 'PORTAL',
  PEER: 'PEER',
});

export default {
  name: 'SelectSyncSourceModal',
  components: {
    SelectSourceModal,
  },
  data() {
    return {
      source: SyncSources.PORTAL,
      initialDelay: true, // hide everything for a second to prevent flicker
      formIsDisabled: true,
      portalIsOffline: false,
      SyncSources,
    };
  },
  created() {
    setTimeout(() => {
      this.initialDelay = false;
    }, 1000);
    // TODO Check to see if KDP is online
    Promise.resolve().then(() => {
      this.portalIsOffline = false;
      this.formIsDisabled = false;
    });
  },
  methods: {
    handleSubmit() {
      if (!this.formIsDisabled) {
        this.$emit('submit', { source: this.source });
      }
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
  $trs: {
    dataPortalLabel: {
      message: 'Kolibri Data Portal (online)',
      context:
        'Label on radio button for Kolibri Data Portal, which requires an internet connection',
    },
    dataPortalDescription: {
      message: 'Sync to Kolibri Data Portal if your facility is registered',
      context: 'Description of the sync option',
    },
    localNetworkLabel: {
      message: 'Local network or internet',
      context: 'Label on radio button for Local or internet',
    },
    localNetworkDescription: {
      message:
        'Sync facility data with another instance of Kolibri on your local network or the internet',
      context: 'Description of the sync option',
    },
  },
};

