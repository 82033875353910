//
//
//
//
//
//
//
//


export default {
  name: 'CoreLogo',
  props: {
    src: {
      type: String,
      required: false,
    },
  },
};

