//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import get from 'lodash/get';
import { mapActions } from 'vuex';
import ReportErrorModal from './ReportErrorModal';

export default {
  name: 'AppError',
  components: {
    ReportErrorModal,
  },
  data() {
    return {
      showDetailsModal: false,
    };
  },
  computed: {
    headerText() {
      if (this.isPageNotFound) {
        return this.$tr('resourceNotFoundHeader');
      }
      return this.$tr('defaultErrorHeader');
    },
    paragraphTexts() {
      if (this.isPageNotFound) {
        return [this.$tr('resourceNotFoundMessage')];
      }
      return [this.$tr('defaultErrorMessage'), this.$tr('defaultErrorResolution')];
    },
    // HACK since the error is stored as a string, we have to re-parse it to get the error code
    errorObject() {
      if (this.$store.state.core.error) {
        try {
          return JSON.parse(this.$store.state.core.error);
        } catch (err) {
          return null;
        }
      }
      return null;
    },
    isPageNotFound() {
      // Returns 'true' only if method is 'GET' and code is '404'.
      // Doesn't handle case where 'DELETE' or 'PATCH' request returns '404'.
      return (
        get(this.errorObject, 'status') === 404 &&
        get(this.errorObject, 'config.method') === 'get'
      );
    },
    exitButtonLabel() {
      return this.$tr('defaultErrorExitPrompt');
    },
  },
  methods: {
    ...mapActions(['handleError']),
    revealDetailsModal() {
      this.showDetailsModal = true;
    },
    hideDetailsModal() {
      this.showDetailsModal = false;
    },
    reloadPage() {
      // reloads without cache
      global.location.reload();
    },
    handleClickBackToHome() {
      this.handleError('');
      this.$router.push({ path: '/' });
    },
  },
  $trs: {
    defaultErrorHeader: 'Sorry! Something went wrong!',
    // eslint-disable-next-line
    defaultErrorExitPrompt: 'Back to home',
    pageReloadPrompt: 'Refresh',
    defaultErrorMessage:
      'We care about your experience on Kolibri and are working hard to fix this issue',
    defaultErrorResolution: 'Try refreshing this page or going back to the home page',
    defaultErrorReportPrompt: 'Help us by reporting this error',
    resourceNotFoundHeader: 'Resource not found',
    resourceNotFoundMessage: 'Sorry, that resource does not exist',
  },
};

